/* SendLotus Language Texts

Table of Contents

*/

export default {
  'en': 'English',
  'vi': 'Vietnamese',
  'language': 'Languages',

  'wallet.RecentTransaction': 'Recent Transactions',
  'wallet.Wallet': 'Wallet',
  'wallet.SentTo': 'Sent to:',
  'wallet.Tokens': 'Tokens',
  'wallet.Send': 'Send',
  'wallet.Settings': 'Settings',
  'wallet.Recieve': 'Recieve',
  'wallet.ExternalMessage': 'External Message',
  'wallet.Reply': 'Reply',
  'wallet.Lixi': 'Lixi',
  'wallet.CongratulationMessage': 'Congratulations on your new wallet!',
  'wallet.StartUsingMessageLine1': 'Start using the wallet immediately to receive',
  'wallet.StartUsingMessageLine2': 'payments, or load it up with',
  'wallet.StartUsingMessageLine3': 'to send to others',

  'send.PushNotificationTitle': 'Enable Push Notification',
  'send.PushNotificationConfirmation': 'Would you like to receive notification of new transaction for your wallets?',
  'send.Yes': 'Yes',
  'send.No': 'No',
  'send.PermisionError': 'Error - Permision Error',
  'send.TickerAddressNotValid': 'Destination is not a valid {ticker} address',
  'send.TransactionSuccessful': 'Transaction successful. Click to view in block explorer.',
  'send.TransactionFail': 'Transaction failed: no response from {restUrl}.',
  'send.CouldNotCommunicateWithAPI': 'Could not communicate with API. Please try again.',
  'send.CanNotSendMessage': 'This address has no outgoing transaction, you cannot send message.',
  'send.NewAddressWarning': 'It looks like this address is NEW, please verify it before sending a large amount.',
  'send.InvalidAddress': 'Invalid {ticker} address',
  'send.NotSupportAddress': 'Token addresses are not supported for {ticker} sends',
  'send.CannotSendToYourself': 'Cannot send to yourself!',
  'send.UnableCalculateMaxValue': 'Unable to calculate the max value due to network errors',
  'send.SendConfirmation': 'Are you sure you want to send {formDataValue} {ticker} to {formDataAddress}',
  'send.HaveZeroTicker': 'You currently have 0 {ticker}',
  'send.DepositFund': 'Deposit some funds to use this feature', 
  'send.TickerAddress': '{ticker} Address', 
  'send.Amount': 'Amount',
  'send.OptionalPrivateMessage': 'Optional Private Message',
  'send.SendButton': 'Send',
  'send.SendOnlyMessage': 'Send only message',
  'send.TooManyUnModalMessage': 'The {ticker} you are trying to send has too many unModal. ed ancestors to send (limit 50). Sending will be possible after a block Modal.ation. Try again in about 10 minutes.',
  'send.AlertQueryParam': 'You are sending a transaction to an address including query parameters "{queryStringText}." Only the "amount" parameter, in units of {ticker} satoshis, is currently supported.',

  'enhanceInput.HigherFee': 'Higher Fee',
  'enhanceInput.TransactionAttached': 'Transaction with attached message will incur',
  'enhanceInput.Encryption': 'Encryption',
  'enhanceInput.MessageEncrypted': 'Message is encrypted and only readable to the intended recipient.',
  'enhanceInput.EncryptedMessageSentTo': 'Encrypted message can only be sent to ',
  'enhanceInput.WalletLeastTransaction': 'wallets with at least 1 outgoing transaction.',
  'enhanceInput.MessageLength': 'Message Length',
  'enhanceInput.DependingLanguage': 'Depending on your language,',
  'enhanceInput.EachCharacrterByte': 'each character may occupy from 1 to 4 bytes.',
  'enhanceInput.EncryptedMaxLength': 'Encrypted message max length is 206 bytes.',

  'setting.ImportMessage': 'Copy and paste your mnemonic seed phrase below to import an existing wallet',
  'setting.Lixi': 'Lixi',
  'setting.RenameWalletTitle': 'Rename Wallet {walletName}',
  'setting.RenameWalletSuccess': 'Wallet "{walletToBeRenamed}" renamed to "{newWalletName}"',
  'setting.RenameWalletFailed': 'Rename failed. All wallets must have a unique name.',
  'setting.DeleteWalletSuccess': 'Wallet "{walletToBeDeleted}" successfully deleted',
  'setting.DeleteWalletFailed': 'Error deleting {walletToBeDeleted}.',
  'setting.DeleteWalletConfirmation': 'Are you sure you want to delete wallet "{walletToBeDeleted}"?',
  'setting.InvalidWalletError': 'Wallet name must be a string between 1 and 24 characters long',
  'setting.EnterWalletName': 'Enter new wallet name',
  'setting.ConfirmNotMatchError': 'Your confirmation phrase must match exactly',
  'setting.DeleteConfirmation': 'Type "delete {walletToBeDeleted}" to confirm',
  'setting.KeepSeedPhraseWarning': 'Your seed phrase is the only way to restore your wallet. Write it down. Keep it safe.',
  'setting.SeeSeedPhrase': 'Click to reveal seed phrase',
  'setting.DownloadQRCode': 'Download your QR code',
  'setting.BackupYourWallet': 'Backup your wallet',
  'setting.ManageWallets': 'Manage Wallets',
  'setting.NewWallet': 'New Wallet',
  'setting.ImportWallet': 'Import Wallet',
  'setting.ValidSeedPhraseRequired': 'Valid mnemonic seed phrase required',
  'setting.Mnemonic': 'mnemonic (seed phrase)',
  'setting.Import': 'Import',
  'setting.CurrentlActive': 'Currently active',
  'setting.Activate': 'Activate',
  'setting.GeneralSettings': 'General Settings',
  'setting.LockApp': 'Lock App',
  'setting.NotSupported': 'Not Supported',
  'setting.GotIt': 'Got It',
  'setting.HowEnableNotification': 'How to enable notification',
  'setting.DeviceSupport': 'This feature works best with Chrome or Brave on Android device',
  'setting.NotSupportIos': 'This feature does not work on IOS and Safari on MacOS',
  'setting.TwoStepEnableNotification': '2 steps to enable notification',
  'setting.AllowNotification': 'Allow notification for the',
  'setting.ForBrowser': 'browser on your device',
  'setting.ThenAllowNotification': 'Then allow notification for',
  'setting.SendlotusOnBrower': 'sendlotus.com on your browser',
  'setting.EnableNotification': 'Enable Notification',
  'setting.GrantPermisson': 'You will be prompted to grant permisson for notification, Please click "Allow"',
  'setting.OK': 'OK',
  'setting.PermisionError': 'Error - Permision Error',
  'setting.Notification': 'Notification',
  'setting.BlockedDevice': 'Blocked by device',

  'lixi.ClaimCode': 'Claim code',
  'lixi.Redeem': 'Redeem'
};
